import React, { useEffect, useState } from "react"
import Layout from "../../../containers/layout"
import {
  getMonthChartByDate,
  lookupChart,
  getDayChartByDate,
} from "../../../cm-lib/QiMen"
import Common from "./common"
import { parseDateString } from "../../../helpers/datetime"
import { getQueryString, assign } from "../../../helpers"
import DateTimePicker from "../../../components/DateTimePicker"
import { format } from "date-fns"

const Home = () => {
  const qs = getQueryString()
  const [dayChart, setDayChart] = useState(null)
  const [monthChart, setMonthChart] = useState(null)
  const [dt] = useState(parseDateString(qs.dt))
  useEffect(() => {
    const chartMeta = getMonthChartByDate(dt)
    const dayChartMeta = getDayChartByDate(dt)
    setMonthChart(lookupChart(chartMeta.structure, chartMeta.pillar))
    setDayChart(lookupChart(dayChartMeta.structure, dayChartMeta.pillar))
  }, [dt])
  return (
    <Layout>
      <Common
        heading="Qi Men Dun Jia Month Chart"
        chartType="Day Chart"
        chart={dayChart}
        initialDate={dt}
      />
      <Common
        heading="Qi Men Dun Jia Month Chart"
        chartType="Month Chart"
        chart={monthChart}
      />
      <DateTimePicker
        onClick={date => {
          const dt = format(date, "yyyy-MM-dd HH:mm")
          assign(`?dt=${dt}`)
        }}
        dateObject={dt}
      />
    </Layout>
  )
}

export default Home
